import { FinalContainerChildrenProps } from '../../../../Interfaces/EmrysNutritionInterface';
import { ChildrenContainer } from './EmrysNutritionStyle';

const FinalContainerChildren: React.FC<FinalContainerChildrenProps> = ({ iconSrc, iconClass, text, padding }) => (
    <ChildrenContainer className={`is-flex is-flex-direction-column is-align-items-center is-justify-content-center box m-3 ${padding}`}>
        <img
            src={iconSrc}
            className={`${iconClass}`}
        />
        <div>{text}</div>
    </ChildrenContainer>
);

export default FinalContainerChildren;
