import React, { useEffect, useState } from 'react';
import {
    ImageContainer,
    Title,
    Line,
    SubTitle,
    Description,
    Container,
    ListItems,
    Item,
    NutritionPicture1,
    ContainerWithBackground,
    AdditionalText,
    DescriptionPict3,
    NutritionPicture4,
    DescriptionPict4,
    DiscoverButton,
    PicturesContainer,
    FinalContainer,
    PictureContainer,
    PrimarySection,
    SecondSection,
    GreenLightCircle,
    GreenCircle,
    GuidLineContainer,
    GuidelineAndPicturesSection,
    NutritionStyled,
} from './EmrysNutritionStyle';
import GuidLineNutrition from './GuidLineNutrition';
import GuidLineNutritionMobile from './GuidLineNutritionMobile';
import FinalContainerChildren from './FinalContainerChildren';
import EmrysNutritionCoverPict from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionCoverPict.webp';
import EmrysNutritionPict1 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict1.jpg';
import EmrysNutritionPict3 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict3.png';
import EmrysNutritionPict4 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict4.png';
import EmrysNutritionPict6 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict6.png';
import EmrysNutritionPict5 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict5.png';
import EmrysNutritionPict7 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict7.png';
import PlusIcon from '@assets/icons/PlusIcon.svg';
import LeafNatureIcon from '@assets/icons/LeafNatureIcon.svg';
import FranceFlagIcon from '@assets/icons/FranceFlagIcon.svg';
import ECIcon from '@assets/icons/ECIcon.svg';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader.tsx';

interface EmrysNutritionProps {
    giftCardLink?: string;
}

const EmrysNutrition: React.FC<EmrysNutritionProps> = ({ giftCardLink }) => {
    const nutritionSiteLink = import.meta.env.VITE_NUTRITION_URL;
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const [animationCompleted, setAnimationCompleted] = useState(false);

    const ItemComponentList = [
        { transformValue: 36, label: t('base.nutrition.wellBeing') },
        { transformValue: 32, label: t('base.nutrition.sleep') },
        { transformValue: 31, label: t('base.nutrition.detox') },
        { transformValue: 29, label: t('base.nutrition.immunity') },
        { transformValue: 32, label: t('base.nutrition.beauty') },
        { transformValue: 36, label: t('base.nutrition.comfort') },
    ];

    const DescriptionItemList = [
        { firstText: t('base.nutrition.nutrition'), secondText: t('base.nutrition.antiAging') },
        { firstText: t('base.nutrition.detox'), secondText: t('base.nutrition.slimming') },
        { firstText: t('base.nutrition.energy'), secondText: t('base.nutrition.immunity') },
        { firstText: t('base.nutrition.relaxation'), secondText: t('base.nutrition.sleep') },
        { firstText: t('base.nutrition.sport'), secondText: t('base.nutrition.performance') },
    ];

    const handlePlusButtonClick = () => {
        setIsDescriptionVisible(!isDescriptionVisible);
    };

    const animateElements = (items: NodeListOf<HTMLElement>, delay: number, onComplete: () => void) => {
        if (items.length) {
            items.forEach((item, index) => {
                setTimeout(() => {
                    item.classList.add('animate');
                    if (index === items.length - 1) {
                        onComplete();
                    }
                }, index * delay);
            });
        }
    };

    const scrollHandler = () => {
        const items = document.querySelectorAll('#list-items > .item') as NodeListOf<HTMLElement>;
        const ItemsContainer = document.querySelector('.ItemsContainer') as HTMLDivElement;

        if (ItemsContainer && ItemsContainer.getBoundingClientRect().top <= window.innerHeight / 2) {
            animateElements(items, 800, () => {
                setAnimationCompleted(true);
            });
        }
    };

    const calculateDynamicValue = (width: number, minValue: number, maxValue: number) => {
        const minWidth = 1024;
        const maxWidth = 2560;

        if (width <= minWidth) return minValue;
        if (width >= maxWidth) return maxValue;

        return ((width - minWidth) / (maxWidth - minWidth)) * (maxValue - minValue) + minValue;
    };

    const [dynamicLeftPosForPictContainer, setDynamicLeftPosForPictContainer] = useState(calculateDynamicValue(window.innerWidth, 100, 1600));
    const [dynamicMLForListItems, setDynamicMLForListItems] = useState(calculateDynamicValue(window.innerWidth, 150, 1500));
    const [dynamicMLForWomenPict, setdynamicMLForWomenPict] = useState(calculateDynamicValue(window.innerWidth, 50, 435));
    const [dynamicTopPosition, setDynamicTopPosition] = useState(calculateDynamicValue(window.innerWidth, 315, 1180));

    const handleResize = () => {
        setDynamicLeftPosForPictContainer(calculateDynamicValue(window.innerWidth, 100, 1600));
        setDynamicMLForListItems(calculateDynamicValue(window.innerWidth, 150, 1500));
        setdynamicMLForWomenPict(calculateDynamicValue(window.innerWidth, 50, 435));
        setDynamicTopPosition(calculateDynamicValue(window.innerWidth, 315, 1180));
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <NutritionStyled className='is-flex is-flex-direction-column is-align-items-center'>
            <ImageContainer>
                <img
                    className='coverPicture'
                    src={EmrysNutritionCoverPict}
                    alt={'cover'}
                />
            </ImageContainer>

            <div className='mb-5'>
                <Title>{t('base.nutrition.title')}</Title>
                <Line />
                <SubTitle>{t('base.nutrition.subTitle')}</SubTitle>
                <Description className='mx-auto'>{t('base.nutrition.titleDescription')}</Description>
            </div>

            <ContainerWithBackground
                className='is-flex is-flex-direction-column'
                $dynamicMarginLeft={dynamicMLForWomenPict}
            >
                <PrimarySection className='ItemsContainer is-flex is-flex-direction-row is-justify-content-space-between mt-5 is-align-items-center'>
                    <ListItems
                        $dynamicMarginLeft={dynamicMLForListItems}
                        id={'list-items'}
                    >
                        {animationCompleted && (
                            <PictureContainer
                                $dynamicLeftPosition={dynamicLeftPosForPictContainer}
                                $animationCompleted={animationCompleted}
                                src={EmrysNutritionPict7}
                                alt='Pict7'
                            />
                        )}
                        {ItemComponentList.map((item, index) => (
                            <Item
                                className='item'
                                key={index}
                                $transformx={item.transformValue}
                            >
                                {item.label}
                            </Item>
                        ))}
                    </ListItems>

                    <Container>
                        <GreenCircle />
                        <GreenLightCircle className='GreenLightCircle' />
                        <NutritionPicture1 src={EmrysNutritionPict1} />
                    </Container>
                </PrimarySection>
                <SecondSection className='is-flex is-flex-direction-column'>
                    <div className='Child1 is-flex is-flex-direction-row is-align-items-center is-justify-content-end'>
                        <p className='pictureDescription'>{t('base.nutrition.desc1')}</p>
                    </div>

                    <div className='Child2 is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-space-around'>
                        <AdditionalText>
                            {t('base.nutrition.desc2A')} <br />
                            <br />
                            {t('base.nutrition.desc2B')}
                        </AdditionalText>
                        <div className='flip-card-container is-flex is-flex-direction-row is-align-items-end is-justify-content-end'>
                            <div className={`flip-card ${isDescriptionVisible ? 'flipped' : ''}`}>
                                <div className='flip-card-front'>
                                    <img
                                        src={EmrysNutritionPict3}
                                        className='Pict3'
                                    />
                                </div>
                                <div className='flip-card-back'>
                                    <DescriptionPict3 className='is-flex is-flex-direction-column is-align-items-center'>
                                        <p className='title'>{t('base.nutrition.flippedCardTitle')}</p>
                                        <p className='body'>“ {t('base.nutrition.flippedCardDesc')} ”</p>
                                    </DescriptionPict3>
                                </div>
                            </div>

                            <div
                                className={`ButtonContainer is-flex is-flex-direction-row is-align-items-center is-justify-content-center ${isDescriptionVisible ? 'ButtonExit' : 'ButtonPlus'}`}
                                onClick={handlePlusButtonClick}
                            >
                                <img src={PlusIcon} />
                            </div>
                        </div>
                    </div>

                    <div className='healthWellnessContainer is-flex is-flex-wrap-wrap-reverse is-align-items-center is-justify-content-space-evenly'>
                        <NutritionPicture4
                            src={EmrysNutritionPict4}
                            className='nutritionImage'
                        />
                        <ul>
                            {DescriptionItemList.map((item, index) => (
                                <DescriptionPict4
                                    className='descriptionItem'
                                    key={index}
                                >
                                    {item.firstText} <span className='ANDSymbole'>&</span> {item.secondText}
                                </DescriptionPict4>
                            ))}
                        </ul>
                    </div>
                </SecondSection>
            </ContainerWithBackground>
            <DiscoverButton
                href={nutritionSiteLink}
                target='_blank'
                rel='noopener noreferrer'
            >
                {t('base.nutrition.discoverBtnLabel')}
            </DiscoverButton>
            <GuidelineAndPicturesSection className='is-flex is-flex-direction-column  is-justify-content-end'>
                <GuidLineContainer>
                    {isMobile ?
                        <GuidLineNutritionMobile />
                    :   <GuidLineNutrition />}
                </GuidLineContainer>

                <PicturesContainer
                    className='is-flex is-flex-wrap-wrap is-align-items-end'
                    $dynamicTopPosition={dynamicTopPosition}
                >
                    <div className='Pict1 is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
                        <img
                            className='pictureStyle'
                            src={EmrysNutritionPict6}
                        />
                        <a
                            className='buttons has-text-white'
                            href={giftCardLink}
                        >
                            {t('base.nutrition.addCardGiftBtn')}
                        </a>
                    </div>
                    <div className='Pict2 is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
                        <img
                            className='pictureStyle'
                            src={EmrysNutritionPict5}
                        />
                        <a
                            className='buttons underlineText has-text-white'
                            href={nutritionSiteLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {t('base.nutrition.discoverBtnLabel')}
                        </a>
                    </div>
                </PicturesContainer>
            </GuidelineAndPicturesSection>

            <FinalContainer className='is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center'>
                <div
                    className={'mt-4 pb-4 has-text-centered'}
                    style={{ width: '100%', fontSize: '20px' }}
                >
                    <span style={{ color: '#2B6E0A' }}>*</span> Seules les cartes cadeaux vous permettent de gagner des avantages fidélité. <br />
                    Les autres moyens de paiement ne vous permettent pas d’en bénéficier.
                </div>
                <FinalContainerChildren
                    iconSrc={LeafNatureIcon}
                    iconClass='Icon1'
                    text={t('base.nutrition.ingredients')}
                    padding={isMobile ? 'p-5' : 'p-3'}
                />
                <FinalContainerChildren
                    iconSrc={FranceFlagIcon}
                    iconClass='Icon2'
                    text={t('base.nutrition.madeInFr')}
                />
                <FinalContainerChildren
                    iconSrc={ECIcon}
                    iconClass='Icon3'
                    text={t('base.nutrition.getEC')}
                    padding='p-3'
                />
            </FinalContainer>
        </NutritionStyled>
    );
};
export default EmrysNutrition;
