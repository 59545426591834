export const uiDeviceDetect = async () =>
    await import('Ui/DeviceDetect')
        .then(module => module.default)
        .catch(() => {
            return () => {
                return { isMobile: false };
            };
        });

export const useDeviceDetect = await uiDeviceDetect();
