import styled, { keyframes } from 'styled-components';
import BackgroundMobile from '@assets/HomepageEmrysBrands/EmrysNutrition/BackgroundMobile.svg';
import EmrysNutritionPict2 from '@assets/HomepageEmrysBrands/EmrysNutrition/EmrysNutritionPict2.jpg';
import Background from '@assets/HomepageEmrysBrands/EmrysNutrition/Background.svg';

export const NutritionStyled = styled.div`
    line-height: 1.5 !important;
`;

export const PrimarySection = styled.div`
    width: 100%;
    position: relative;
    bottom: 195px;
    ${props => props.theme.devices.desktopToFullhd} {
        bottom: 240px;
    }
    ${props => props.theme.devices.mobile} {
        bottom: 160px;
    }
`;
export const SecondSection = styled.div`
    position: relative;
    bottom: 280px;
`;
export const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    .coverPicture {
        width: 100%;
    }
`;

export const BreadcrumbsOverlay = styled.div`
    position: absolute;
    left: 57px;
    top: 40px;
    ${props => props.theme.devices.mobile} {
        top: 5px;
        left: 25px;
    }
    max-width: 100%;
`;

export const Title = styled.p`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size1};
    font-weight: 500;
    line-height: 78px;
    line-height: 78px;
    text-align: center;
    background: linear-gradient(180deg, ${props => props.theme.colors.greenDarker} 59.5%, ${props => props.theme.colors.greenLight} 100%);
    -webkit-background-clip: text;
    color: transparent;
    margin-top: 30px;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize1};
        font-weight: 700;
        line-height: 41.6px;
    }
`;

export const Line = styled.hr`
    width: 503px;
    ${props => props.theme.devices.mobile} {
        width: 270px;
    }
    border: 2px solid transparent;
    border-image: linear-gradient(90deg, #003087 0.4%, #ffffff 50.2%, #eb001b 100%);
    border-image-slice: 1;
    margin: 20px auto 20px;
    height: unset;
`;

export const SubTitle = styled.p`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size5};
    font-weight: 400;
    line-height: 23.4px;
    text-align: center;
`;

export const Description = styled.p`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    background: linear-gradient(180deg, ${props => props.theme.colors.greyDark} 59.5%, rgba(168, 168, 167, 0.53) 100%);
    -webkit-background-clip: text;
    color: transparent;
    margin-top: 30px;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size6};
        color: ${props => props.theme.colors.greyDark};
        background: transparent;
        line-height: 20.83px;
        width: 80%;
    }
`;

export const Container = styled.div`
    position: relative;
    margin-right: 40px;

    ${props => props.theme.devices.desktop} {
        margin-right: 100px;
    }
`;

export const NutritionPicture1 = styled.img`
    z-index: 2;
    position: relative;

    object-fit: cover;
    width: 178.78px;
    height: 262px;

    ${props => props.theme.devices.mobile} {
        width: 178.78px;
        height: 262px;
        margin-bottom: 3em;
    }

    ${props => props.theme.devices.tabletOnly} {
        width: 234px;
        height: 441px;
    }

    ${props => props.theme.devices.desktop} {
        width: 465px;
        height: 682px;
    }
    -webkit-box-shadow: 0px 5px 5px -1px rgb(189 188 188);
    box-shadow: 0px 5px 5px -1px rgb(189 188 188);
`;

const Circle = styled.div`
    width: 192px;
    height: 192px;

    ${props => props.theme.devices.desktop} {
        width: 500px;
        height: 500px;
    }

    ${props => props.theme.devices.mobile} {
        width: 192px;
        height: 192px;
    }

    ${props => props.theme.devices.tabletOnly} {
        width: 300px;
        height: 300px;
    }
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    ${props => props.theme.devices.desktop} {
        top: 63px;
    }
`;

export const GreenCircle = styled(Circle)`
    border: 3px solid ${props => props.theme.colors.green};
    top: 25px;
    right: 23px;

    ${props => props.theme.devices.tabletOnly} {
        top: 50px;
        right: 40px;
    }

    ${props => props.theme.devices.mobile} {
        top: 25px;
        right: 23px;
    }

    ${props => props.theme.devices.desktop} {
        right: 35px;
    }
    max-width: 100%;
`;

export const GreenLightCircle = styled(Circle)`
    border: 6px solid ${props => props.theme.colors.greenLight};
    top: 23px;
    right: 37px;

    ${props => props.theme.devices.tabletOnly} {
        top: 50px;
        right: 80px;
    }

    ${props => props.theme.devices.mobile} {
        top: 23px;
        right: 37px;
    }

    ${props => props.theme.devices.desktop} {
        right: 78px;
    }
    max-width: 100%;
`;

export const ListItems = styled.ul<{ $dynamicMarginLeft: number }>`
    margin-bottom: 30px;
    margin-left: 30px;
    z-index: 2;
    ${props => props.theme.devices.mobile} {
        padding-right: 90px;
    }

    ${props => props.theme.devices.desktop} {
        margin-bottom: 90px;
        margin-left: ${props => props.$dynamicMarginLeft}px;
    }

    ${props => props.theme.devices.tabletOnly} {
        margin-bottom: 90px;
        margin-left: 250px;
    }
`;

export const Item = styled.li<{ $transformx: number }>`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size4};
    font-weight: 400;
    line-height: 26.04px;
    text-align: left;
    margin: 50px;
    color: ${props => props.theme.colors.greyDark};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size6};
        line-height: 24px;
        margin: 10px;
    }
    transition: all 0.05s ease-in-out;
    &.animate {
        opacity: 1;
        transform: translateX(${props => props.$transformx - 23}vw);

        ${props => props.theme.devices.mobile} {
            transform: translateX(${props => props.$transformx - 14}vw);
        }
    }
`;

const showPictureContainer = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const PictureContainer = styled.img<{ $dynamicLeftPosition: number; $animationCompleted: boolean }>`
    margin-top: 20px;
    position: absolute;
    left: ${props => props.$dynamicLeftPosition}px;
    ${props => props.theme.devices.mobile} {
        height: 100px;
        left: 20px;
    }
    opacity: 0;
    animation-name: ${props => props.$animationCompleted && showPictureContainer};
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
`;

export const ContainerWithBackground = styled.div<{ $dynamicMarginLeft: number }>`
    width: 100%;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 220px;
    ${props => props.theme.devices.mobile} {
        align-items: center;
        background-image: url(${BackgroundMobile});
        background-size: contain;
        top: 170px;
    }
    .Child1 {
        background-image: url(${EmrysNutritionPict2});
        background-size: cover;
        background-repeat: no-repeat;
        width: 533px;
        height: 336px;
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.size8};
        font-weight: 500;
        line-height: 38.4px;
        color: ${props => props.theme.colors.green};
        padding: 20px;
        margin-left: ${props => props.$dynamicMarginLeft}px;
        margin-top: 0;
        ${props => props.theme.devices.desktopToFullhd} {
            margin-top: 60px;
        }
        ${props => props.theme.devices.tablet} {
            margin-top: 60px;
        }
        ${props => props.theme.devices.mobile} {
            font-size: ${props => props.theme.globalSize.size6};
            line-height: 24px;
            width: 313px;
            height: 191px;
            margin-left: 20px;
            margin-top: 145px;
        }
        .pictureDescription {
            width: 34%;
        }
    }
    .Child2 {
        margin-top: 6rem;
        ${props => props.theme.devices.desktopToFullhd} {
            margin-top: 2rem;
        }
        ${props => props.theme.devices.mobile} {
            margin-top: 0rem;
        }
    }
    .Pict3 {
        box-shadow: 0px 6px 17px 0px ${props => props.theme.colors.greyLight};
        width: 300px;
        height: 316px;

        ${props => props.theme.devices.desktop} {
            width: 375px;
            height: 394px;
        }
        border-radius: 7.5px;
    }
    .flip-card-container {
        position: relative;
        perspective: 1000px;
        ${props => props.theme.devices.mobile} {
            margin: auto;
        }
    }
    .flip-card {
        width: 300px;
        height: 316px;
        ${props => props.theme.devices.desktop} {
            width: 374px;
            height: 394px;
        }
        position: relative;
        transition: transform 0.6s;
        transform-style: preserve-3d;
    }
    .flipped {
        transform: rotateY(180deg);
    }
    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 300px;
        height: 316px;
        ${props => props.theme.devices.desktop} {
            width: 374px;
            height: 394px;
        }
        backface-visibility: hidden;
        border-radius: 7.5px;
    }
    .flip-card-back {
        transform: rotateY(180deg);
    }
    .ButtonContainer {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        margin: 15px;
        cursor: pointer;
        transition: opacity 2s;
        &.ButtonPlus {
            background: ${props => props.theme.colors.green};
        }
        &.ButtonExit {
            rotate: 45deg;
            background: ${props => props.theme.colors.greyLight};
        }
        &:hidden {
            opacity: 0;
        }
    }
    .healthWellnessContainer {
        position: relative;
        top: 80px;
        ${props => props.theme.devices.mobile} {
            top: 50px;
        }
        .nutritionImage {
            position: relative;
        }
    }
`;

export const AdditionalText = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size4};
    font-weight: 400;
    line-height: 26.04px;
    color: #575756;
    width: 420px;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size6};
        line-height: 24px;
        text-align: center;
        margin: 30px auto 30px;
        width: 320px;
    }
`;

export const DescriptionPict3 = styled.div`
    background: ${props => props.theme.colors.greenDarker};
    box-shadow: 0px 6px 17px 0px ${props => props.theme.colors.greyLight};
    width: 300px;
    height: 316px;

    ${props => props.theme.devices.desktop} {
        width: 374px;
        height: 394px;
    }
    border-radius: 7.5px;
    font-family: ${props => props.theme.fonts.familyPrimary};
    text-align: center;
    padding: 20px;
    .title {
        font-size: ${props => props.theme.globalSize.size5};
        font-weight: 700;
        line-height: 23.4px;
        color: ${props => props.theme.colors.white};
    }
    .body {
        font-size: ${props => props.theme.globalSize.size6};
        font-weight: 400;
        line-height: 24px;
        color: ${props => props.theme.colors.white};
        margin-top: 50px;
    }
`;

export const DescriptionPict4 = styled.li`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size4};
    font-weight: 400;
    line-height: 26.04px;
    text-align: center;
    color: ${props => props.theme.colors.greyDark};
    list-style-type: none;
    margin: 35px;
    span.ANDSymbole {
        color: ${props => props.theme.colors.greenDarker};
    }
    ${props => props.theme.devices.mobile} {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size5};
        line-height: 23.4px;
    }
`;

export const NutritionPicture4 = styled.img`
    position: absolute;
    z-index: 1;
    width: 500px;
    height: 467.88px;
    ${props => props.theme.devices.mobile} {
        width: 292px;
        height: 273px;
        margin-top: 50px;
    }
`;

export const DiscoverButton = styled.a`
    width: 187px;
    height: 40px;
    padding: 9px 24px 8px 24px;
    border-radius: 6px;
    background: ${props => props.theme.colors.greenDarker};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size7};
    font-weight: 500;
    letter-spacing: 0.005em;
    text-align: center;
    color: ${props => props.theme.colors.white} !important;
    margin-bottom: 50px;
    margin-top: 120px;
    z-index: 2;
    text-decoration: underline;
    &:hover {
        background: ${props => props.theme.colors.greenLight} !important;
        color: ${props => props.theme.colors.greenDarker} !important;
    }
    ${props => props.theme.devices.mobile} {
        margin-top: 0;
        margin-bottom: 0;
        z-index: 2;
    }
`;

export const GuidLineComponent = styled.div`
    width: 100%;
    .GuidLineTitle {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.size2};
        font-weight: 700;
        line-height: 36.46px;
        text-align: center;
    }
    .GuidLineText {
        color: ${props => props.theme.colors.greyDark};
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size6};
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .GuidLineNumber {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: 48px;
        font-weight: 500;
        line-height: 62.5px;
    }
    .GuidLineNumberMobile {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.size1};
        font-weight: 700;
        line-height: 41.6px;
    }
`;

export const GuidelineAndPicturesSection = styled.div`
    width: 100%;
    position: relative;
`;

export const GuidLineContainer = styled.div`
    ${props => props.theme.devices.mobile} {
        position: relative;
        top: 40px;
    }
`;

export const PicturesContainer = styled.div<{ $dynamicTopPosition: number }>`
    width: 100%;
    z-index: 1;
    justify-content: center;

    ${props => props.theme.devices.tablet} {
        position: unset;
    }
    ${props => props.theme.devices.widescreen} {
        justify-content: end;
        position: absolute;
        top: ${props => props.$dynamicTopPosition}px;
    }

    .buttons {
        height: 40px;
        padding: 11px 24px 11px 24px;
        background: ${props => props.theme.colors.greenDarker};
        border-radius: 6px;
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 500;
        line-height: 18.2px;
        text-align: center;
        color: ${props => props.theme.colors.white};
    }
    .buttons:hover {
        background: ${props => props.theme.colors.greenLight} !important;
        color: ${props => props.theme.colors.greenDarker} !important;
    }
    .underlineText {
        text-decoration: underline;
    }
    .Pict1 {
        ${props => props.theme.devices.desktop} {
            margin-right: 150px;
        }
        ${props => props.theme.devices.mobile} {
            margin-right: 0;
        }
        .pictureStyle {
            ${props => props.theme.devices.mobile} {
                margin-bottom: 20px;
            }
        }
    }
    .Pict2 {
        ${props => props.theme.devices.desktop} {
            margin-right: 50px;
        }
        ${props => props.theme.devices.mobile} {
            margin-right: 0;
            margin-top: 40px;
        }
        .pictureStyle {
            width: 417px;
            height: 422px;
            ${props => props.theme.devices.mobile} {
                width: 251px;
                height: 253px;
            }
        }
    }
`;

export const FinalContainer = styled.div`
    position: relative;
    margin-bottom: 200px;
    top: 250px;
    ${props => props.theme.devices.mobile} {
        position: unset;
        margin-top: 70px;
        margin-bottom: 0;
    }
    .Icon1 {
        margin: 0.5rem;
        width: 34.18px;
        height: 40.39px;
        ${props => props.theme.devices.mobile} {
            width: 23.27px;
            height: 27.5px;
        }
    }
    .Icon2 {
        margin-bottom: 0.75rem;
        width: 46px;
        height: 38px;
        ${props => props.theme.devices.mobile} {
            margin-bottom: 1.5rem;
            width: 28px;
            height: 22px;
        }
    }
    .Icon3 {
        margin: 0.75rem;
        width: 41px;
        height: 41px;
        ${props => props.theme.devices.mobile} {
            margin: 0rem 0rem 0.25rem 0rem;
            width: 28px;
            height: 28px;
        }
    }
`;

export const ChildrenContainer = styled.div`
    width: 325px;
    height: 172px;
    border-radius: 9px;
    border: 2px solid ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size5};
    font-weight: 700;
    line-height: 23.4px;
    text-align: center;
    color: ${props => props.theme.colors.greyDark};
    ${props => props.theme.devices.mobile} {
        width: 175px;
        height: 110px;
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: center;
    }
`;
